package ru.musintimur.rubca.constants

const val ADMIN_PANEL_SECTION = "admin-panel-section"
const val ADMIN_PANEL_PANE_SECTION = "admin-panel-pane-section"
const val RELEASES_SECTION = "releases-section"
const val ADVANCED_SEARCH_SECTION = "advanced-search-section"
const val ADVANCED_SEARCH_RESULT_SECTION = "advanced-search-result-section"
const val AUTHENTICATE_SECTION = "authenticate-section"
const val CONTAINER_BOOTSTRAP = "container"
const val DROPDOWN_BOOTSTRAP = "dropdown"
const val DROPDOWN_BOOTSTRAP_MENU = "dropdown-menu"
const val DROPDOWN_BOOTSTRAP_TOGGLE = "dropdown-toggle"
const val DROPDOWN_TOP = "dropdown-top-block"
const val CARD_BOOTSTRAP_BORDER_SUCCESS = "border-success"
const val COLUMN_BOOTSTRAP_AUTO = "col-auto"