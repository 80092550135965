package ru.musintimur.rubca.extensions

const val APOSTROPHE = "'"
const val AMPERSAND = "&"
const val ARRAY_SEPARATOR = "&||&"

fun String.replaceHtmlApostrophe(): String =
    this.replace("&#39;", APOSTROPHE)

fun String.replaceHtmlAmpersand(): String =
    this.replace("&amp;", AMPERSAND)

fun String?.splitCharacteristics(separator: String = ARRAY_SEPARATOR): Set<String> =
    this?.split(separator)?.toSet() ?: emptySet()

private val symbolsMap = mapOf (
    '%' to "%25", //always first!!!
    '+' to "%2B",
    '/' to "%2F",
    '&' to "%26",
    '?' to "%3F",
    ' ' to "+" //always last!!!
)

fun String.encodeUrlSymbols(): String {
    var newString = this
    symbolsMap.forEach { (t, u) ->
        newString = newString.replace(t.toString(), u)
    }
    return newString
}

fun String.decodeUrlSymbols(): String =
    this.replace('+', ' ')

