package ru.musintimur.rubca.web.javascript.inputs

import kotlinx.browser.document
import org.w3c.dom.HTMLCollection
import org.w3c.dom.get
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.ACTIVE_TAGS_API
import ru.musintimur.rubca.constants.INPUT_TAG_NAME
import ru.musintimur.rubca.model.entities.Tag
import ru.musintimur.rubca.web.api.responses.ResponseTags
import ru.musintimur.rubca.web.javascript.extensions.asHTMLInputElement
import ru.musintimur.rubca.web.javascript.extensions.frmt
import ru.musintimur.rubca.web.javascript.extensions.getFullApiUrl
import ru.musintimur.rubca.web.javascript.extensions.setupGet

fun setupTagNameInputs() {
    val tagInputs = document.getElementsByClassName(INPUT_TAG_NAME)
    if (tagInputs.length > 0) {
        val request = XMLHttpRequest()
        request.setupGet(ACTIVE_TAGS_API.getFullApiUrl()) {
            runCatching {
                val response = request.responseText
                val responseTags = JSON.parse<ResponseTags>(response).tags.unsafeCast<Array<Tag>>()
                bindTagDictionary(tagInputs, responseTags.map { it.tagName })
            }.onFailure {
                it.frmt()
            }
        }.send()
    }
}

private fun bindTagDictionary(tagInputs: HTMLCollection, tagDictionary: List<String>) {
    for (i in 0 until tagInputs.length) {
        tagInputs[i]?.asHTMLInputElement()?.let {
            setupAutocomplete(it, tagDictionary)
        }
    }
}