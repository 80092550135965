package ru.musintimur.rubca.web.javascript.links

import kotlinx.browser.document
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.get
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.*
import ru.musintimur.rubca.web.javascript.extensions.*

fun setupAdvancedSearchPaginationAnchors() {
    document.getElementsByClassName(LINKS_JS_KOTLIN).let { links ->
        for (i in 0 until links.length) {
            links[i]?.asHTMLAnchorElement()?.let { anchor ->
                when {
                    anchor.classList.contains(LINK_ADVANCED_SEARCH_RESULT_PAGE) ||
                            anchor.classList.contains(LINK_ADVANCED_SEARCH_RESULT_SORT) -> {
                        anchor.onclick = { event ->
                            setupAdvancedSearchPagination(anchor)
                            event.preventDefault()
                        }
                    }
                }
            }
        }
    }
}

private fun setupAdvancedSearchPagination(ref: HTMLAnchorElement) {
    val data = ref.collectParameters()
    val block = document.getElementById(BLOCK_OF_ADVANCED_SEARCH_RESULTS)

    val request = XMLHttpRequest()
    request.setupGet(ADVANCED_SEARCH_RESULT_PAGE.getLocalApiUrl().applyUrlParams(data)) {
        block?.outerHTML = request.responseText
        setupAdvancedSearchPaginationAnchors()
    }.send()
}