package ru.musintimur.rubca.web.javascript

import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.SECRETS_API
import ru.musintimur.rubca.web.api.responses.ResponseSecrets
import ru.musintimur.rubca.web.javascript.extensions.getLocalApiUrl
import ru.musintimur.rubca.web.javascript.extensions.setupGet
import ru.musintimur.rubca.web.javascript.forms.setupForms
import ru.musintimur.rubca.web.javascript.inputs.setupInputs
import ru.musintimur.rubca.web.javascript.links.setupLinks

lateinit var secrets: ResponseSecrets

fun main() {
    setupAll()
}

fun setupAll() {
    val request = XMLHttpRequest()
    request.setupGet(SECRETS_API.getLocalApiUrl(), false) {
        secrets = JSON.parse<ResponseSecrets>(request.responseText)
        setupForms()
        setupLinks()
        setupInputs()
    }.send()
}
