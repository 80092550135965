package ru.musintimur.rubca.web.javascript.forms

import org.w3c.dom.HTMLFormElement
import org.w3c.dom.get
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.ARTIST_CORRECTION
import ru.musintimur.rubca.constants.BUTTON_BOOTSTRAP_PRIMARY
import ru.musintimur.rubca.constants.TEXTAREA_ARTIST_CORRECT_NAMES
import ru.musintimur.rubca.web.api.responses.Status
import ru.musintimur.rubca.web.javascript.extensions.*

fun setupFormArtistNameCorrection(form: HTMLFormElement) {
    val data: dynamic = form.collectParameters()

    val textArea = form.getElementsByClassName(TEXTAREA_ARTIST_CORRECT_NAMES)[0]?.asHTMLTextAreaElement()
    val btn = form.getElementsByClassName(BUTTON_BOOTSTRAP_PRIMARY)[0]?.asHTMLInputElement()

    val request = XMLHttpRequest()
    request.setupPost(ARTIST_CORRECTION.getFullApiUrl()) {
        runCatching {
            val res = request.responseText
            textArea?.value = JSON.parse<Status>(res).status
            textArea?.disabled = true
            btn?.disabled = true
        }.onFailure {
            it.frmt()
        }
    }.send(data)
}