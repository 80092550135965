package ru.musintimur.rubca.web.javascript.forms

import kotlinx.browser.document
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.get
import ru.musintimur.rubca.constants.*

fun setupForms() {
    val forms = document.forms
    for (i in 0 until forms.length) {
        forms[i]?.let { item ->
            val form = item as HTMLFormElement
            when {
                form.classList.contains(FORM_SUBDOMAIN_PARAMETERS) -> {
                    form.onsubmit = { event ->
                        setupFormSubdomainParameters(form)
                        event.preventDefault()
                    }
                }
                form.classList.contains(FORM_SUBDOMAIN_PARSING) -> {
                    form.onsubmit = { event ->
                        setupFormSubdomainParsing(form)
                        event.preventDefault()
                    }
                }
                form.classList.contains(FORM_ARTIST_NAME_CORRECTION) -> {
                    form.onsubmit = { event ->
                        setupFormArtistNameCorrection(form)
                        event.preventDefault()
                    }
                }
                form.classList.contains(FORM_ADVANCED_SEARCH) -> {
                    setupFormAdvancedSearch(form)
                    form.onsubmit = { event ->
                        setupFormAdvancedSearchSubmit(form)
                        event.preventDefault()
                    }
                }
                form.classList.contains(FORM_FIND_SUBDOMAIN) -> {
                    form.onsubmit = { event ->
                        setupFormSubdomainSearchSubmit(form)
                        event.preventDefault()
                    }
                }
                form.classList.contains(FORM_AUTHENTICATE) -> {
                    form.onsubmit = { event ->
                        setupFormAuthenticate(form)
                        event.preventDefault()
                    }
                }
                form.classList.contains(FORM_TAG_NAME_CORRECTION) -> {
                    setupFormTagNameCorrection(form)
                }
            }
        }
    }
}