package ru.musintimur.rubca.web.javascript.extensions


import kotlinx.browser.window
import ru.musintimur.rubca.web.javascript.secrets

fun String.getFullApiUrl(): String {
    fun getHost(): String {
        val servHost = secrets.servHost
        val servPort = secrets.servPort
        return "$servHost:$servPort"
    }
    val servScheme = secrets.servScheme

    return "${servScheme}://${getHost()}/$this".also { console.log(it) }
}

fun String.getLocalApiUrl(): String =
    "${window.location.href.substringBefore(':')}://${window.location.host}/${this.removeFirstBackslash()}".also { console.log(it) }


fun String.applyUrlParams(parameters: String): String {
    val params = if (parameters.isNotBlank()) "?$parameters" else ""

    return "$this$params"
}

fun String.removeFirstBackslash() = if (this.first() == '/') this.drop(1) else this