package ru.musintimur.rubca.web.javascript.forms

import kotlinx.browser.window
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.get
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.AUTH_PAGE
import ru.musintimur.rubca.web.javascript.extensions.*

fun setupFormAuthenticate(form: HTMLFormElement) {
    val data: dynamic = form.collectParameters()
    val errorParagraph = form.getElementsByTagName("p")[0]?.asHTMLParagraphElement()

    val request = XMLHttpRequest()
    request.setupPost(AUTH_PAGE.getLocalApiUrl()) {
        when (request.status.toInt()) {
            200 -> window.location.href = "/"
            else -> errorParagraph?.innerText = request.responseText
        }
    }.send(data)
}