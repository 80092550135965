package ru.musintimur.rubca.web.javascript.extensions

import org.w3c.dom.HTMLInputElement
import ru.musintimur.rubca.extensions.encodeUrlSymbols

fun HTMLInputElement.params(): String {
    val common: Boolean = this.name.isNotBlank() && this.value.isNotBlank()
    val special: Boolean = when(this.type) {
        "checkbox" -> this.checked
        else -> true
    }
    return if (common && special) {
        "${this.name}=${this.value.encodeUrlSymbols()}&"
    } else {
        ""
    }
}