package ru.musintimur.rubca.constants

const val DUMMY_API = "$API/dummy"

//RELEASES
const val ADVANCED_SEARCH_API = "$API/advanced-search"
const val ADVANCED_SEARCH_RESULT_PAGE = "$API/advanced-search-result-page"
const val ALL_RELEASES = "$API/releases"
const val BESTSELLERS_API = "$API/bestsellers"
const val EDITOR_CHOICE_API = "$API/editors-choice"
const val SEARCH_API = "$API/search"
const val YEAR_RANGE_API = "$API/year-range"

//SUBDOMAINS
const val ALL_SUBDOMAINS = "$API/subdomains"
const val SUBDOMAINS_PAGE = "$API/subdomains-page"
const val SUBDOMAINS_PARSE = "$API/subdomains-parse"
const val SUBDOMAINS_SEARCH = "$API/subdomains-search"
const val SUBDOMAINS_UPDATE = "$API/subdomains-update"

//ARTISTS
const val ARTIST_API = "$API/artist/{name}"
const val ARTIST_CORRECTION = "$API/artist-correction"
const val ARTIST_CORRECTION_PAGE = "$API/artist-correction-page"
const val ARTIST_DISCOGRAPHY_PAGE = "$API/artist-discography-page"
const val ARTISTS_FOR_CORRECTION = "$API/artists-for-correction"

//CITIES
const val ACTIVE_CITIES_API = "$API/active-cities"
const val CITY_API = "$API/city/{name}"

//TAGS
const val ACTIVE_TAGS_API = "$API/active-tags"
const val TAG_API = "$API/tag/{name}"
const val TAG_CORRECTION = "$API/tag-correction"
const val TAG_CORRECTION_PAGE = "$API/tag-correction-page"
const val TAGS_FOR_CORRECTION = "$API/tags-for-correction"

//USERS & AUTH
const val AUTH_PAGE = "/login"
const val AUTH_API = "$API/auth"
const val CHANGE_USER_API = "$API/change-user"
const val USER_GROUP_API = "$API/user-group-api"
const val SECRETS_API = "$API/secrets"