package ru.musintimur.rubca.web.javascript.forms

import kotlinx.browser.document
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.get
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.*
import ru.musintimur.rubca.web.api.responses.ResponseCity
import ru.musintimur.rubca.web.javascript.extensions.*
import ru.musintimur.rubca.web.javascript.inputs.setupAutocomplete
import ru.musintimur.rubca.web.javascript.links.setupAdvancedSearchPaginationAnchors

fun setupFormAdvancedSearch(form: HTMLFormElement) {
    val years = mutableListOf<Int>()

    val yearsRequest = XMLHttpRequest()
    yearsRequest.setupGet(YEAR_RANGE_API.getFullApiUrl()) {
        runCatching {
            val res = yearsRequest.responseText
            years.addAll(JSON.parse<Array<Int>>(res))
            setupYearFields(form, years)
        }.onFailure {
            it.frmt()
        }
    }.send()

    form.getElementsByClassName(INPUT_CITY_NAME)[0]?.asHTMLInputElement()?.let { inputCity ->
        val request = XMLHttpRequest()
        request.setupGet(ACTIVE_CITIES_API.getFullApiUrl()) {
            runCatching {
                val res = request.responseText
                val responseCities = JSON.parse<Array<ResponseCity>>(res)
                setupAutocomplete(inputCity, responseCities.map { it.cityName })
            }.onFailure {
                it.frmt()
            }
        }.send()
    }

//    form.getElementsByClassName(INPUT_TAG_NAME)[0]?.asHTMLInputElement()?.let { inputTag ->
//        val request = XMLHttpRequest()
//        request.setupGet(ACTIVE_TAGS_API.getFullApiUrl()) {
//            runCatching {
//                val res = request.responseText
//                val responseTags = JSON.parse<ResponseTags>(res).tags
//                setupAutocomplete(inputTag, responseTags.map { it.tagName })
//            }.onFailure {
//                it.frmt()
//            }
//        }.send()
//    }
}

fun setupYearFields(form: HTMLFormElement, years: List<Int>) {
    form.getElementsByClassName(INPUT_MIN_YEAR_VALUE)[0]?.asHTMLInputElement()?.let { inputYear ->
        setupAutocomplete(inputYear, years.map { it.toString() }, true)
    }

    form.getElementsByClassName(INPUT_MAX_YEAR_VALUE)[0]?.asHTMLInputElement()?.let { inputYear ->
        setupAutocomplete(inputYear, years.map { it.toString() }, true)
    }
}

fun setupFormAdvancedSearchSubmit(form: HTMLFormElement) {
    val data: String = form.collectParameters()
    val block = document.getElementById(BLOCK_OF_ADVANCED_SEARCH_RESULTS)

    val request = XMLHttpRequest()
    request.setupGet(ADVANCED_SEARCH_RESULT_PAGE.getLocalApiUrl().applyUrlParams(data)) {
        block?.outerHTML = request.responseText
        setupAdvancedSearchPaginationAnchors()
    }.send()
}

