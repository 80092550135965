package ru.musintimur.rubca.web.javascript.forms

import org.w3c.dom.HTMLCollection
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.get
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.*
import ru.musintimur.rubca.web.api.responses.Status
import ru.musintimur.rubca.web.javascript.extensions.*

fun setupFormTagNameCorrection(form: HTMLFormElement) {
    val inputTagName = form.getElementsByClassName(INPUT_TAG_NAME)[0]?.asHTMLInputElement()
    val textArea = form.getElementsByTagName("p")[0]?.asHTMLParagraphElement()
    val buttons = form.getElementsByTagName("button")
    val submit = form.getElementsByClassName(BUTTON_BOOTSTRAP_PRIMARY)[0]?.asHTMLInputElement()

    form.onsubmit = { event ->
        if (inputTagName?.value?.isNotBlank() == true) {
            val data: dynamic = form.collectParameters()

            val request = XMLHttpRequest()
            request.setupPost(TAG_CORRECTION.getFullApiUrl()) {
                runCatching {
                    val res = request.responseText
                    textArea?.innerText = JSON.parse<Status>(res).status
                    inputTagName.disabled = true
                    disableButtons(buttons)
                    submit?.disabled = true
                }.onFailure {
                    it.frmt()
                }
            }.send(data)
        }

        event.preventDefault()
    }

    for (i in 0 until buttons.length) {
        buttons[i]?.asHTMLButtonElement()?.let { button ->
            button.onclick = {
                var params: String = form.collectParameters()
                    when {
                    button.classList.contains(BUTTON_BOOTSTRAP_SUCCESS) ->
                        params += "&setApproved=true"
                    button.classList.contains(BUTTON_BOOTSTRAP_SECONDARY) ->
                        params += "&setCity=true"
                    button.classList.contains(BUTTON_BOOTSTRAP_DANGER) ->
                        params += "&setTrash=true"
                }
                val data: dynamic = params
                val request = XMLHttpRequest()
                request.setupPost(TAG_CORRECTION.getFullApiUrl()) {
                    runCatching {
                        val res = request.responseText
                        textArea?.innerText = JSON.parse<Status>(res).status
                        inputTagName?.disabled = true
                        disableButtons(buttons)
                        submit?.disabled = true
                    }.onFailure {
                        it.frmt()
                    }
                }.send(data)
            }
        }
    }
}

fun disableButtons(buttons: HTMLCollection) {
    for (i in 0 until buttons.length) {
        buttons[i]?.asHTMLButtonElement()?.disabled = true
    }
}