package ru.musintimur.rubca.constants

const val DROPDOWN_SORT_NEWEST = "Новые"
const val DROPDOWN_SORT_OLDEST = "Старые"
const val DROPDOWN_SORT_AZ = "A-Z"
const val DROPDOWN_SORT_ZA = "Z-A"
const val DROPDOWN_SORT_POPULAR = "Популярные"

const val DROPDOWN_ARTIST_DISCOGRAPHY_NEWEST = "ddArtistNewestReleases"
const val DROPDOWN_ARTIST_DISCOGRAPHY_OLDEST = "ddArtistOldestReleases"
const val DROPDOWN_ARTIST_DISCOGRAPHY_AZ = "ddArtistAzReleases"
const val DROPDOWN_ARTIST_DISCOGRAPHY_ZA = "ddArtistZaReleases"
const val DROPDOWN_ARTIST_DISCOGRAPHY_POPULAR = "ddArtistPopularReleases"
const val DROPDOWN_ARTIST_DISCOGRAPHY_UNDEFINED = "ddArtistUndefinedReleases"

val artistDiscographySortDropdown: Map<String, String> =
    mapOf(
        DROPDOWN_SORT_NEWEST to DROPDOWN_ARTIST_DISCOGRAPHY_NEWEST,
        DROPDOWN_SORT_OLDEST to DROPDOWN_ARTIST_DISCOGRAPHY_OLDEST,
        DROPDOWN_SORT_AZ to DROPDOWN_ARTIST_DISCOGRAPHY_AZ,
        DROPDOWN_SORT_ZA to DROPDOWN_ARTIST_DISCOGRAPHY_ZA,
        DROPDOWN_SORT_POPULAR to DROPDOWN_ARTIST_DISCOGRAPHY_POPULAR
    )