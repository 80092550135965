package ru.musintimur.rubca.web.javascript.forms

import org.w3c.dom.HTMLFormElement
import org.w3c.dom.get
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.BUTTON_BOOTSTRAP_SECONDARY
import ru.musintimur.rubca.constants.SUBDOMAINS_PARSE
import ru.musintimur.rubca.web.javascript.extensions.*

fun setupFormSubdomainParsing(form: HTMLFormElement) {
    val data: dynamic = form.collectParameters()
    val btn = form.getElementsByClassName(BUTTON_BOOTSTRAP_SECONDARY)[0]?.asHTMLInputElement()
    val oldText = btn?.value ?: "Синхронизировать"
    btn?.value = "Идёт синхронизация..."

    XMLHttpRequest().setupPost(SUBDOMAINS_PARSE.getFullApiUrl()) {
        btn?.value = oldText
    }.send(data)
}