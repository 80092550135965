package ru.musintimur.rubca.web.javascript.forms

import kotlinx.browser.document
import org.w3c.dom.HTMLFormElement
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.BLOCK_OF_SUBDOMAINS_PAGE
import ru.musintimur.rubca.constants.SUBDOMAINS_PAGE
import ru.musintimur.rubca.web.javascript.extensions.applyUrlParams
import ru.musintimur.rubca.web.javascript.extensions.collectParameters
import ru.musintimur.rubca.web.javascript.extensions.getLocalApiUrl
import ru.musintimur.rubca.web.javascript.extensions.setupGet
import ru.musintimur.rubca.web.javascript.setupAll

fun setupFormSubdomainSearchSubmit(form: HTMLFormElement) {
    val data: String = form.collectParameters()
    val block = document.getElementById(BLOCK_OF_SUBDOMAINS_PAGE)

    val request = XMLHttpRequest()
    request.setupGet(SUBDOMAINS_PAGE.getLocalApiUrl().applyUrlParams(data)) {
        block?.outerHTML = request.responseText
        setupAll()
    }.send()
}