package ru.musintimur.rubca.web.javascript.extensions

import org.w3c.dom.*

fun Element.asHTMLInputElement(): HTMLInputElement? = runCatching {
    this as HTMLInputElement
}.onFailure {
    it.frmt()
}.getOrNull()

fun Element.asHTMLTextAreaElement(): HTMLTextAreaElement? = runCatching {
    this as HTMLTextAreaElement
}.onFailure {
    it.frmt()
}.getOrNull()

fun Element.asHTMLAnchorElement(): HTMLAnchorElement? = runCatching {
    this as HTMLAnchorElement
}.onFailure {
    it.frmt()
}.getOrNull()

fun Element.asHTMLParagraphElement(): HTMLParagraphElement? = runCatching {
    this as HTMLParagraphElement
}.onFailure {
    it.frmt()
}.getOrNull()

fun Element.asHTMLButtonElement(): HTMLButtonElement? = runCatching {
    this as HTMLButtonElement
}.onFailure {
    it.frmt()
}.getOrNull()