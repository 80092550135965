package ru.musintimur.rubca.web.javascript.links

import kotlinx.browser.document
import org.w3c.dom.HTMLAnchorElement
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.ARTIST_DISCOGRAPHY_PAGE
import ru.musintimur.rubca.constants.BLOCK_OF_ARTIST_DISCOGRAPHY
import ru.musintimur.rubca.web.javascript.extensions.applyUrlParams
import ru.musintimur.rubca.web.javascript.extensions.collectParameters
import ru.musintimur.rubca.web.javascript.extensions.getLocalApiUrl
import ru.musintimur.rubca.web.javascript.extensions.setupGet
import ru.musintimur.rubca.web.javascript.setupAll

fun setupArtistDiscographySortPage(ref: HTMLAnchorElement) {
    val data = ref.collectParameters()
    val block = document.getElementById(BLOCK_OF_ARTIST_DISCOGRAPHY)

    val request = XMLHttpRequest()
    request.setupGet(ARTIST_DISCOGRAPHY_PAGE.getLocalApiUrl().applyUrlParams(data)) {
        block?.outerHTML = request.responseText
        setupAll()
    }.send()
}