package ru.musintimur.rubca.web.javascript.inputs

import kotlinx.browser.document
import kotlinx.dom.addClass
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import ru.musintimur.rubca.constants.DROPDOWN_BOOTSTRAP_MENU
import ru.musintimur.rubca.constants.FORM_ACTION_VOID
import ru.musintimur.rubca.web.javascript.extensions.asHTMLAnchorElement
import ru.musintimur.rubca.web.javascript.extensions.asHTMLInputElement

fun setupAutocomplete(input: HTMLInputElement, items: List<String>, onStart: Boolean = false) {
    var currentFocus = -1

    fun addActive(elements: HTMLCollection) {
        fun removeActive(elements: HTMLCollection) {
            for (i in 0 until elements.length) {
                elements[i]?.classList?.remove("autocomplete-active")
            }
        }

        if (elements.length == 0) return
        removeActive(elements)
        if (currentFocus >= elements.length) {
            currentFocus = 0
        }
        if (currentFocus < 0) {
            currentFocus = elements.length - 1
        }
        elements[currentFocus]?.classList?.add("autocomplete-active")
    }

    fun closeAllLists(element: HTMLElement? = null) {
        val autos = document.getElementsByClassName("autocomplete-items")
        for (i in 0 until autos.length) {
            if (element != autos[i] && element != input) {
                autos[i]?.let { it.parentNode?.removeChild(it) }
            }
        }
    }

    fun createListItem(item: String, value: String): HTMLAnchorElement? {
        val start = item.indexOf(value, 0, true)
        return document.createElement("A").asHTMLAnchorElement()?.apply {
            addClass("dropdown-item")
            href = FORM_ACTION_VOID
            setAttribute("data-toggle", "list")
            setAttribute("aria-controls", "home")
            innerHTML = "${item.subSequence(0, start)}"
            innerHTML += "<strong>${item.subSequence(start, start + value.length)}</strong>"
            innerHTML += "${item.subSequence(start + value.length until item.length)}"
            innerHTML += "<input type='hidden' value='${item}'>"
            addEventListener("click", fun(_: Event) {
                input.value = getElementsByTagName("input")[0]?.asHTMLInputElement()?.value ?: value
                closeAllLists()
            })
        }
    }

    input.addEventListener("input", fun(_: Event) {
        val value = input.value
        closeAllLists()
        if (value.isBlank()) return
        currentFocus = -1
        val a = document.createElement("DIV").apply {
            id = "${input.id}autocomplete-list"
            addClass("autocomplete-items")
            addClass(DROPDOWN_BOOTSTRAP_MENU)
            setAttribute("aria-labelledby", "dropdownMenuButton")
        }
        input.parentNode?.appendChild(a)
        val itemStartsList =  mutableListOf<HTMLAnchorElement>()
        val itemContainsList =  mutableListOf<HTMLAnchorElement>()
        for (item in items) {
            when {
                item.startsWith(value, true) -> {
                    createListItem(item, value)?.let { itemStartsList.add(it) }
                }
                !onStart && !item.startsWith(value, true) && item.contains(value, true) -> {
                    createListItem(item, value)?.let { itemContainsList.add(it) }
                }
            }
        }
        for (item in itemStartsList) { a.appendChild(item) }
        for (item in itemContainsList) { a.appendChild(item) }
        a.addClass("show")
        a.parentElement?.addClass("show")
    })

    input.addEventListener("keydown", fun(e: Event) {
        document.getElementById("${input.id}autocomplete-list")?.let {
            val elements = it.getElementsByTagName("A")
            when ((e as? KeyboardEvent)?.keyCode) {
                40 -> {
                    currentFocus = currentFocus.inc()
                    addActive(elements)
                }
                38 -> {
                    currentFocus = currentFocus.dec()
                    addActive(elements)
                }
                13 -> {
                    e.preventDefault()
                    if (currentFocus > -1 && elements.length > 0)
                        (elements[currentFocus] as? HTMLElement)?.click()
                }
            }
        }
    })

    document.addEventListener("click", fun(e: Event){
        closeAllLists(e.target as? HTMLElement)
    })
}