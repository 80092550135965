package ru.musintimur.rubca.web.javascript.extensions

import org.w3c.dom.*

fun HTMLAnchorElement.collectParameters(): String {
    var params = ""
    for (i in 0 until this.children.length) {
        when (val element = this.children[i]) {
            is HTMLInputElement -> {
                params += element.params()
            }
            is HTMLTextAreaElement -> {
                params += element.params()
            }
        }
    }
    return params.dropLast(1).also { "Link parameters: ${console.log(it)}" }
}