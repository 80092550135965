package ru.musintimur.rubca.web.javascript.extensions

import org.w3c.dom.events.Event
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.web.javascript.secrets

fun XMLHttpRequest.setupPost(url: String, onLoadEnd: (Event) -> Unit): XMLHttpRequest {
    this.onloadend = onLoadEnd
    this.open("POST", url, true)
    this.setRequestHeader("Content-type", "application/x-www-form-urlencoded; charset=utf-8")
    this.setRequestHeader("X-Forwarded-Proto", "https")
    this.setAuthHeader()
    return this
}

fun XMLHttpRequest.setupGet(url: String, withAuth: Boolean = true, onLoadEnd: (Event) -> Unit): XMLHttpRequest {
    this.onloadend = onLoadEnd
    this.open("GET", url, true)
    this.setRequestHeader("X-Forwarded-Proto", "https")
    if (withAuth) this.setAuthHeader()
    return this
}

private fun XMLHttpRequest.setAuthHeader() {
    this.setRequestHeader("Authorization", token())
}

private fun token(): String = "Bearer ${secrets.jwtToken}"