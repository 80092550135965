package ru.musintimur.rubca.web.javascript.links

import kotlinx.browser.document
import org.w3c.dom.HTMLAnchorElement
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.ARTIST_CORRECTION_PAGE
import ru.musintimur.rubca.constants.BLOCK_OF_ARTIST_CORRECTION_PAGE
import ru.musintimur.rubca.web.javascript.extensions.applyUrlParams
import ru.musintimur.rubca.web.javascript.extensions.collectParameters
import ru.musintimur.rubca.web.javascript.extensions.setupGet
import ru.musintimur.rubca.web.javascript.setupAll

fun setupArtistCorrectionPagination(ref: HTMLAnchorElement) {
    val data = ref.collectParameters()
    val block = document.getElementById(BLOCK_OF_ARTIST_CORRECTION_PAGE)

    val request = XMLHttpRequest()
    request.setupGet(ARTIST_CORRECTION_PAGE.applyUrlParams(data)) {
        block?.outerHTML = request.responseText
        setupAll()
    }.send()
}