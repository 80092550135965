package ru.musintimur.rubca.web.javascript.forms

import org.w3c.dom.HTMLFormElement
import org.w3c.dom.get
import org.w3c.xhr.XMLHttpRequest
import ru.musintimur.rubca.constants.SUBDOMAINS_UPDATE
import ru.musintimur.rubca.web.javascript.extensions.*

fun setupFormSubdomainParameters(form: HTMLFormElement) {
    val data: dynamic = form.collectParameters()

    XMLHttpRequest().setupPost(SUBDOMAINS_UPDATE.getFullApiUrl()) {
        form.getElementsByTagName("p")[0]?.innerHTML = "OK"
    }.send(data)
}